import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
  issuer: 'https://phiagroup.onelogin.com/oidc/2',
  redirectUri: window.location.origin + '/ignite/auth-callback',
  clientId: 'bf310bf0-656d-013c-b211-4ec0421f26c5177516',
  responseType: 'code',
  scope: 'openid profile email params',
  showDebugInformation: true,
  postLogoutRedirectUri: window.location.origin,
};

export const environment = {
  production: false,
  authCodeFlowConfig: authCodeFlowConfig,
  restHost: 'https://provider-experience-engine-dev.azurewebsites.net/api',
  // restHost: 'http://localhost:3000/api',
  supportUrl: 'https://support.example.com',
};
