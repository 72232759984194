import { Injectable } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public user$: Observable<any> = this.userSubject.asObservable();
  constructor(private oauthService: OAuthService, private http: HttpClient) {
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthEvent) {
        if (event.type === 'token_received') {
          this.userLoginSuccess();
          this.fetchUserDetails().subscribe();
        }
      }
    });
   
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow();
  }

  public logout() {
    this.oauthService.logOut();
  }
  public refresh() {
    this.oauthService.silentRefresh();
  }
  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }
  public runInitialLoginSequence(): Promise<boolean> {
    this.oauthService.configure(environment.authCodeFlowConfig);
    return this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (this.hasValidToken()) {
        this.fetchUserDetails().subscribe();
        return true;
      }
      return false;
    });
  }
  userLoginSuccess() {
    console.log('User login success');
  }

  public get accessToken() {
    return this.oauthService.getAccessToken();
  }
  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }
  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }
  public get idToken() {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }
  fetchUserDetails(): Observable<any> {
    return this.http
      .get<any>(environment.restHost+'/user/me')
      .pipe(tap((user) => this.userSubject.next(user)));
  }
  fetchUserDetailsMock(): Observable<any> {
    const mockUser = { can_update: true, name: 'Admin', email: '' };
    return of(mockUser).pipe(tap((user) => this.userSubject.next(user)));
  }
  getUserDetails(): any {
    return this.userSubject.value;
  }
  isAdmin(): boolean {
    const user = this.userSubject.value;
    return user && user.system_admin;
  }
  canUpdate(): boolean {
    const user = this.userSubject.value;
    return user && user.can_update;
  }
}
